import { Tab, Tabs, TabPanel, TabList } from 'react-tabs';
import {productSlider } from '../../utils/data';
import ProductColumnView from './ProductColumnView';
import OwlCarouselSlider from '../owl-carousel';
import { useState } from 'react';


function TrendyCollection( props ) {
    const { products, loading=false } = props;
    const [tabValue, setTabValue] = useState('');
    var categories = products && products.map(item => item.Category);
    categories = categories.filter((item, index) => categories.indexOf(item) === index);

    const HandleTabChange = (val) => {
        setTabValue(val);
    }

    return (
        <Tabs defaultIndex={ 0 } selectedTabClassName="show" >
            <div className="bg-lighter trending-products trendy">
                <div className="heading heading-flex mb-3">
                    <div className="heading-left">
                        <h2 className="title">Trending Today</h2>
                    </div>

                    <div className="heading-right">
                        <TabList className="nav nav-pills justify-content-center">
                            <Tab className="nav-item" onClick={() => HandleTabChange("")}>
                                <span className="nav-link">All</span>
                            </Tab>
                            {categories.length > 0 && categories.map((cat, index) => (
                                <Tab className="nav-item" key={index} onClick={() => HandleTabChange(cat)}>
                                    <span className="nav-link">{cat}</span>
                                </Tab>
                            ))}
                        </TabList>
                    </div>
                </div>
                <div className="tab-content tab-content-carousel">
                    <TabPanel>
                        {
                            loading ?
                                <OwlCarouselSlider adClass="owl-simple carousel-equal-height carousel-with-shadow" options={ productSlider }>
                                    {
                                        [ 1, 2, 3, 4, 5, 6 ].map( ( item, index ) =>
                                            <div className="skel-pro" key={ index }></div>
                                        )
                                    }
                                </OwlCarouselSlider>
                                :
                                <OwlCarouselSlider adClass="owl-simple carousel-equal-height carousel-with-shadow" options={ productSlider }>
                                    {
                                        products && products.map( ( item, index ) =>
                                            <ProductColumnView
                                                product={ item }
                                                key={ index } />
                                        )
                                    }
                                </OwlCarouselSlider>
                        }
                    </TabPanel>
                    {categories.length > 0 && categories.map((cat, index) => (
                        <TabPanel key={index}>
                            {
                                loading ?
                                    <OwlCarouselSlider adClass="owl-simple carousel-equal-height carousel-with-shadow" options={ productSlider }>
                                        {
                                            [ 1, 2, 3, 4, 5, 6 ].map( ( item, index ) =>
                                                <div className="skel-pro" key={ index }></div>
                                            )
                                        }
                                    </OwlCarouselSlider>
                                    :
                                    <OwlCarouselSlider adClass="owl-simple carousel-equal-height carousel-with-shadow" options={ productSlider }>
                                        {
                                            products && products
                                            .filter(item => item.Category === tabValue)
                                            .map( ( item, index ) =>
                                                <ProductColumnView
                                                    product={ item }
                                                    key={ index } />
                                            )
                                        }
                                    </OwlCarouselSlider>
                            }
                        </TabPanel>
                    ))};
                </div>
            </div>
        </Tabs>
    )
}

export default TrendyCollection;
