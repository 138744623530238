import React from 'react';
import PolicyHeader from "../header/Policy-header";
import "./policies.css";
import PageHeader from "../header/page-header";
import Footer from "../Footer";

function Payment() {
  return (
    <div className='policies'>
        <PolicyHeader />
        <PageHeader title={"Payment Policy"} subTitle="" />
        <div className="container">
            <div className="row">
                <div className="col-md-12 section">
                    <div className='right_text'>
                        <span>Last updated: 12th July 2024</span>
                    </div>
                    <div className='content'>
                        <p>Welcome to OCEAN 3D! We strive to provide a seamless and secure shopping experience. Please review our payment policy below.</p>
                        <h2>Accepted Payment Methods</h2>
                        <p>We accept the following payment methods: COD (Cash on Delivery), Razorpay, and PhonePe.</p>
                        <h3>COD Payment Conditions</h3>
                        <ul>
                            <li>COD is only allowed for orders placed on the shop site if the user is logged in and has a verified mobile number for shipping.</li>
                            <li>COD is not allowed for guest orders.</li>
                            <li>COD is not allowed for orders placed on the print site.</li>
                        </ul>
                        
                        <h2>Payment Processing</h2>
                        <p>Payments are processed immediately upon checkout. You will receive an email confirmation once your payment is successfully processed.</p>
                        
                        <h2>Pricing and Currency</h2>
                        <p>All prices are listed in INR. Additional fees such as taxes and shipping costs will be calculated at checkout.</p>
                        
                        <h2>Refund and Exchange Policy</h2>
                        <p>For details on our refund and exchange policy, please visit <a href="https://ocean3d.in/cancellation-returns-refund-policy">OCEAN 3D Cancellation, Returns & Refund Policy</a>. Please contact our customer support to initiate a refund or exchange.</p>
                        
                        <h2>Security Measures</h2>
                        <p>Your payment information is protected with SSL encryption and processed through secure payment gateways. We do not store your payment details.</p>
                        
                        <h2>Payment Disputes</h2>
                        <p>If you encounter any issues with your payment, please contact our customer support at <a href="mailto:support@ocean3d.in">support@ocean3d.in</a> or call/WhatsApp <a href="tel:+917013099789">+91 70130 99789</a> for assistance.</p>
                        
                        <h2>Contact Information</h2>
                        <p>For any payment-related inquiries, please reach out to our customer support:</p>
                        <ul>
                            <li>Email: <a href="mailto:support@ocean3d.in">support@ocean3d.in</a></li>
                            <li>Support Portal: <a href="https://support.ocean3d.in">https://support.ocean3d.in</a></li>
                            <li>Phone/WhatsApp: <a href="tel:+917013099789">+91 70130 99789</a></li>
                        </ul>
                        
                        <p>Thank you for shopping with us!</p>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default Payment