import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import StickyHeader from './StickyHeader';
import CategoryMenu from './category-menu';
import MainMenu from './main-menu';
import HeaderSearch from './HeaderSearch';
import TopHeader from './TopHeader';
import {ReactComponent as OfferIcon} from './offers.svg';

function Header ({quickLinks}) {
    const [siteLogo, setSiteLogo] = useState( "https://cdn.ocean3d.in/global/logo.png");
    const ref = useRef(null);
    const headerHight = document.getElementsByClassName("header-middle");
    const height = headerHight.length > 0 ? headerHight[0].offsetHeight : 0;

    function openMobileMenu () {
        document.querySelector( 'body' ).classList.add( 'mmenu-active' );
    }
  
    const scrollHandler = useCallback(() => {
        if(window.scrollY > height){
          setSiteLogo("https://cdn.ocean3d.in/global/logo-white.png");
          document.querySelector( '.category-dropdown .dropdown-menu' ).classList.remove( 'show' );
        }else{
            document.querySelector( '.category-dropdown .dropdown-menu' ).classList.add( 'show' );
            setSiteLogo('https://cdn.ocean3d.in/global/logo.png');
        }
    },[height])
    
    useEffect( () => {
        window.addEventListener("scroll", scrollHandler);
    }, [scrollHandler] )

    function OnClickOffers(){
        window.open(`${process.env.REACT_APP_ACCOUNT_URL}coupons`, '_blank');
    }


    return (
        <header className="header header-14">
            <div className="header-top bg-body-tertiary">
                <TopHeader />    
             </div>
            <div ref={ref} className="header-middle sticky-header">
                <div className='container-fluid'>
                    <div className="row py-2">
                        <div className="col-auto col-lg-3 col-xl-3 col-xxl-2">
                            <button className="mobile-menu-toggler" onClick={ openMobileMenu }>
                                <span className="sr-only">Toggle mobile menu</span>
                                <i className="icon-bars"></i>
                            </button>

                            <Link to="/" className="logo">
                                <img src={siteLogo} alt="ocean-logo"/>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
                            </Link>
                        </div>
                        <div className="col col-lg-9 col-xl-9 col-xxl-10 header-middle-right">
                            <div className="row">
                                <div className="col-lg-8 col-xxl-4-5col d-none d-lg-block">
                                    <HeaderSearch />
                                </div>
                                <div className="col-lg-4 col-xxl-5col d-flex justify-content-end align-items-center">
                                    <div className="header-dropdown-link">
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <StickyHeader headerHight={headerHight}>
                <div className="header-bottom sticky-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-auto col-lg-3 col-xl-3 col-xxl-2 header-left">
                                <CategoryMenu quickLinks={quickLinks} />
                            </div>
                            <div className="col col-lg-6 col-xl-6 col-xxl-8 header-center">
                                <MainMenu />
                            </div>

                            <div className="col col-lg-3 col-xl-3 col-xxl-2 header-right" onClick={OnClickOffers}>
                                <OfferIcon /><p>Offers</p>
                            </div>
                        </div>

                    </div>
                </div>
            </StickyHeader>
        </header>
    )
}

export default Header;