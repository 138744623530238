import React from 'react';
import useSize from '../Size/useSize';

function CategoryMenu({quickLinks}) {

    const width = useSize();

    function toggleDropdown( e ) {
        e.preventDefault();

        if ( document.querySelector( '.category-dropdown .dropdown-menu' ).classList.contains( 'show' ) ) {
            document.querySelector( '.category-dropdown .dropdown-menu' ).classList.remove( 'show' );
        } else {
            document.querySelector( '.category-dropdown .dropdown-menu' ).classList.add( 'show' );
        }
    }

    return (
        <div className="dropdown category-dropdown">
            <span className="dropdown-toggle" title="Quick Links" onClick={ ( e ) => { toggleDropdown( e ); } }>
                Quick Links
            </span>
            <div className={`dropdown-menu ${width > 1200 ? "show": ''}`}>
                <nav className="side-nav">
                    <ul className="menu-vertical sf-arrows sf-js-enabled" style={ { touchAction: 'pan-y' } }>
                        {quickLinks && quickLinks.map((link, index) => (
                            <li key={index}>
                                <a className="text-dark" href={link.URL}>
                                <i className={`bi bi-${link.Icon}`}></i>{link.Title}</a>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </div>
    );
}

export default CategoryMenu; 