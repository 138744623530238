import { LazyLoadImage } from 'react-lazy-load-image-component';
import OwlCarouselSlider from '../owl-carousel';
import { productSlider1 } from '../../utils/data';
import ProductColumnView from './ProductColumnView';

function ProductsCollection( props ) {
    const { products, loading=false, banner } = props;

    return (
        <div className="row cat-banner-row electronics">
            <div className="col-xl-3 col-xxl-2">
                <div className="cat-banner row no-gutters">
                        {banner &&
                            <div className="banner banner-overlay">
                            <a href={banner.ButtonUrl}>
                                <div className="lazy-overlay"></div>
                                <LazyLoadImage
                                    alt={banner.AltText}
                                    src={banner.ImageUrl}
                                    threshold={ 200 }
                                    width={ 240 }
                                    height={ 400 }
                                    effect="blur"
                                />
                                <div className="banner-content">
                                    <h4 className="banner-subtitle text-white">
                                        <a href="/shop/sidebar/list">{banner.Caption}</a>
                                    </h4>

                                    <h3 className="banner-title text-white">
                                        <a href="/shop/sidebar/list">
                                        {banner.Title}
                                        <br />
                                            <span className='text-white'>{banner.SubTitle}</span>
                                        </a>
                                    </h3>

                                    <a
                                        href={banner.ButtonUrl}
                                        className="banner-link"
                                    >
                                        {banner.ButtonText}
                                    </a>
                                </div>
                            </a>
                        </div>}
                </div>
            </div>
            <div className="col-xl-9 col-xxl-10">
                {
                    loading  ?
                        <OwlCarouselSlider adClass="owl-simple owl-full carousel-equal-height carousel-with-shadow" options={ productSlider1 }>
                            {
                                [ 1, 2, 3, 4, 5, 6 ].map( ( item, index ) =>
                                    <div className="skel-pro" key={ index }></div>
                                )
                            }
                        </OwlCarouselSlider>
                        :
                        <OwlCarouselSlider adClass="owl-simple owl-full carousel-equal-height carousel-with-shadow" options={ productSlider1 }>
                            {
                                products && products.map( ( item, index ) =>
                                    <ProductColumnView
                                        product={ item }
                                        key={ index } />
                                )
                            }
                        </OwlCarouselSlider>
                }
            </div>
            <div className='mb-4'></div>
        </div>
    )
}

export default ProductsCollection;
