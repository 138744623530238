import React from 'react'

function Loader() {
  return (
    <span className="cover">
        <div className="loader">
            <img src="https://cdn.ocean3d.in/global/loader.gif" alt="loader" width="100" />
        </div>
    </span>
  )
}

export default Loader;