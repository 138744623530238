import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function ProductColumnView ( props ) {
    const { product, index} = props;

    return (
        <div className="product product-7 text-center w-100" key={index}>
            <a href={ `${process.env.REACT_APP_SHOP_URL}product/${product.DefaultVariant || product.SKU}` }>
                <figure className="product-media-loaded">
                    <LazyLoadImage
                        alt={ product.Name }
                        src={ product.ImageURL }
                        threshold={ 500 }
                        effect="black and white"
                        wrapperClassName="product-image"
                    />
                    <div className="product-action d-none">
                        <a href={ `${process.env.REACT_APP_SHOP_URL}product/${product.DefaultVariant || product.SKU}` } className="btn-product btn-cart btn-select">
                            <span>select options</span>
                        </a>
                    </div>
                </figure>
                <div className="product-body">
                    <h3 className="product-title">
                        <a href={ `${process.env.REACT_APP_SHOP_URL}product/${product.DefaultVariant || product.SKU}` }>{ product.Name }</a>
                    </h3>

                   {product.SalePrice ?
                    <div className="product-price">
                        <span className="out-price"><del>₹{ product.Price}</del>{" "}₹{ product.SalePrice}</span>
                    </div>
                    :
                    <div className="product-price">
                        <span className="out-price">Start from <del>₹{ product.MinPrice}</del>{" "}₹{ product.MinSalePrice}</span>
                    </div>
                    }

                    <div className="ratings-container">
                        <div className="ratings">
                            <div className="ratings-val" style={ { width: product.AvgRating * 20 + '%' } }></div>
                            <span className="tooltip-text">{ product.AvgRating.toFixed( 2 ) }</span>
                        </div>
                        <span className="ratings-text">( { product.TotalRatings } Reviews )</span>
                    </div>
                </div>
            </a>
        </div>
    )
}

export default ProductColumnView