import React from "react";
import PolicyHeader from "../header/Policy-header";
import "./policies.css";
import PageHeader from "../header/page-header";
import Footer from "../Footer";

function Shipping() {
  return (
    <div className="policies">
      <PolicyHeader />
      <PageHeader title={"Shipping & Delivery Policy"} subTitle="" />
      <div className="container">
        <div className="row">
          <div className="col-md-12 section">
            <h1>Shipping & Delivery Policy</h1>
            <div className="right_text">
              <span>Last updated: 12th July 2024</span>
            </div>
            <div className="content">
              <p>
                Please carefully review our Shipping & Delivery Policy when
                purchasing our products. This policy will apply to any order you
                place with us.
              </p>

              <h2>What are my shipping & delivery options?</h2>
              <p>
                We offer various shipping options. In some cases a third-party
                supplier may be managing our inventory and will be responsible
                for shipping your products.
              </p>

              <h2>Shipping rates</h2>
              <p>
                Shipping rates and estimated delivery times will be displayed at
                checkout. You can select your desired shipping option, and we
                will follow up after you have placed the order with any
                additional shipping information if required.
              </p>
              <p>
                All times and dates given for delivery of the products are given
                in good faith but are estimates only.
              </p>
              <p>
                Once your order is packaged, we will send an email with your
                tracking information.
              </p>

              <h2>Free shipping</h2>
              <ul>
                <li>We offer free standard shipping for orders over 999 INR</li>
                <li>We offer free express shipping for orders over 2499 INR</li>
              </ul>

              <h2>Minimum and Maximum Shipping Time Frame</h2>
              <ul>
                <li>Minimum Shipping Time Frame: 1 day</li>
                <li>Maximum Shipping Time Frame: 4 days</li>
              </ul>

              <h2>Minimum and Maximum Delivered Timeline</h2>
              <p>Depending on user location and the selected shipping options (standard/express):</p>
              <ul>
                <li>Minimum Delivered Timeline: 2 days</li>
                <li>Maximum Delivered Timeline: 10 days</li>
              </ul>

              <h2>Do you deliver internationally?</h2>
              <p>We do not offer international shipping.</p>

              <h2>What happens if my delivery is delayed?</h2>
              <p>
                If delivery is delayed for any reason we will let you know as
                soon as possible and will advise you of a revised estimated date
                for delivery.
              </p>

              <h2>Questions about returns & cancellations?</h2>
              <p>
                If you have questions about returns, please review our Return
                Policy:{" "}
                <a href="https://ocean3d.in/cancellation-returns-refund-policy">
                  https://ocean3d.in/cancellation-returns-refund-policy
                </a>
              </p>
              <h2>How can you contact us about questions in the policy?</h2>
              <ul>
                <li>Phone: +91 7013099879</li>
                <li>
                  Email:{" "}
                  <a href="mailto:policies@kroila3d.in">policies@kroila3d.in</a>
                </li>
                <li>
                  Online contact form:{" "}
                  <a href="https://support.ocean3d.in">
                    https://support.ocean3d.in
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Shipping;
