import React from "react";
import PolicyHeader from "../header/Policy-header";
import "./policies.css";
import PageHeader from "../header/page-header";
import Footer from "../Footer";

function Privacy() {
  return (
    <div className="policies">
      <PolicyHeader />
      <PageHeader title={"Privacy Policy"} subTitle="" />
      <div className="container">
        <div className="row">
          <div className="col-md-12 section">
            <div className="right_text">
              <span>Last updated: 12th July 2024</span>
            </div>
            <div className="content">
              <p>
                This Privacy Policy describes our policies and procedures on the
                collection, use and disclosure of your information when you use
                the Service and tells you about your privacy rights and how the
                law protects you.
              </p>

              <p>
                By using the Service, you agree to the collection and use of
                information in accordance with this Privacy Policy. If you do
                not agree, please do not use or access our Service.
              </p>

              <h2>Definitions</h2>
              <p>For the purposes of this Privacy Policy:</p>
              <ul>
                <li>
                  <strong>Account</strong> means a unique account created for
                  you to access our Service or parts of our Service.
                </li>
                <li>
                  <strong>Affiliate</strong> means an entity that controls, is
                  controlled by or is under common control with a party, where
                  "control" means ownership of 50% or more of the shares, equity
                  interest or other securities entitled to vote for election of
                  directors or other managing authority.
                </li>
                <li>
                  <strong>Company</strong> (referred to as either "the Company",
                  "we", "us" or "our" in this Agreement) refers to KROILA 3D
                  Private Limited, 304, 4th floor, Yerramsetty towers,
                  Narasaraopet - 522601.
                </li>
                <li>
                  <strong>Cookies</strong> are small files that are placed on
                  your computer, mobile device or any other device by a website,
                  containing the details of your browsing history on that
                  website among its many uses.
                </li>
                <li>
                  <strong>Country</strong> refers to: Andhra Pradesh, India.
                </li>
                <li>
                  <strong>Device</strong> means any device that can access the
                  Service such as a computer, a cell phone or a digital tablet.
                </li>
                <li>
                  <strong>Personal Data</strong> is any information that relates
                  to an identified or identifiable individual.
                </li>
                <li>
                  <strong>Service</strong> refers to the Website.
                </li>
                <li>
                  <strong>Service Provider</strong> means any natural or legal
                  person who processes the data on behalf of the Company. It
                  refers to third-party companies or individuals employed by the
                  Company to facilitate the Service, to provide the Service on
                  behalf of the Company, to perform Services related to the
                  Service or to assist the Company in analysing how the Service
                  is used.
                </li>
                <li>
                  <strong>Usage Data</strong> refers to data collected
                  automatically, either generated by the use of the Service or
                  from the Service infrastructure itself (for example, the
                  duration of a page visit).
                </li>
                <li>
                  <strong>Website</strong> refers to OCEAN 3D, accessible from{" "}
                  <a href="https://ocean3d.in">https://ocean3d.in</a>.
                </li>
                <li>
                  <strong>You</strong> mean the individual accessing or using
                  the Service, or the company, or other legal entity on behalf
                  of which such individual is accessing or using the Service, as
                  applicable.
                </li>
              </ul>

              <h2>Collecting and Using Your Personal Data</h2>

              <h3>Types of Data Collected</h3>

              <h4>Personal Data</h4>
              <p>
                While using Our Service, We may ask you to provide us with
                certain personally identifiable information that can be used to
                contact or identify you. Personally identifiable information may
                include, but is not limited to:
              </p>
              <ul>
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Phone number</li>
                <li>Address, State, Province, ZIP/Postal code, City</li>
              </ul>

              <h4>Usage Data</h4>
              <p>
                Usage Data is collected automatically when using the Service.
                Usage Data may include information such as your Device's
                Internet Protocol address (e.g. IP address), browser type,
                browser version, the pages of our Service that you visit, the
                time and date of your visit, the time spent on those pages,
                unique device identifiers and other diagnostic data.
              </p>

              <p>
                When you access the Service by or through a mobile device, We
                may collect certain information automatically, including, but
                not limited to, the type of mobile device you use, your mobile
                device unique ID, the IP address of your mobile device, your
                mobile operating system, the type of mobile Internet browser you
                use, unique device identifiers and other diagnostic data.
              </p>

              <p>
                We may also collect information that your browser sends whenever
                you visit our Service or when you access the Service by or
                through a mobile device.
              </p>

              <h4>Tracking Technologies and Cookies</h4>
              <p>
                We use Cookies and similar tracking technologies to track the
                activity on our Service and store certain information. Tracking
                technologies used are beacons, tags, and scripts to collect and
                track information and to improve and analyse our Service. The
                technologies we use may include:
              </p>
              <ul>
                <li>
                  <strong>Cookies or Browser Cookies.</strong> A cookie is a
                  small file placed on your Device. You can instruct your
                  browser to refuse all Cookies or to indicate when a Cookie is
                  being sent. However, if you do not accept Cookies, You may not
                  be able to use some parts of our Service. Unless you have
                  adjusted your browser setting so that it will refuse Cookies,
                  our Service may use Cookies.
                </li>
                <li>
                  <strong>Web Beacons.</strong> Certain sections of our Service
                  and our emails may contain small electronic files known as web
                  beacons (also referred to as clear gifs, pixel tags, and
                  single-pixel gifs) that permit the Company, for example, to
                  count users who have visited those pages or opened an email
                  and for other related website statistics (for example,
                  recording the popularity of a certain section and verifying
                  system and server integrity).
                </li>
              </ul>

              <p>
                Cookies can be "Persistent" or "Session" Cookies. Persistent
                Cookies remain on Your personal computer or mobile device when
                you go offline, while Session Cookies are deleted as soon as you
                close your web browser. Learn more about cookies on the{" "}
                <a href="https://www.freeprivacypolicy.com/blog/cookies/">
                  Free Privacy Policy website article
                </a>
                .
              </p>

              <p>
                We use both Session and Persistent Cookies for the purposes set
                out below:
              </p>

              <ul>
                <li>
                  <strong>Necessary / Essential Cookies</strong>
                  <br />
                  <strong>Type:</strong> Session Cookies
                  <br />
                  <strong>Administered by:</strong> Us
                  <br />
                  <strong>Purpose:</strong> These Cookies are essential to
                  provide you with Services available through the Website and to
                  enable you to use some of its features. They help to
                  authenticate users and prevent fraudulent use of user
                  accounts. Without these Cookies, the Services that you have
                  asked for cannot be provided, and we only use these Cookies to
                  provide you with those Services.
                </li>
                <li>
                  <strong>Cookies Policy / Notice Acceptance Cookies</strong>
                  <br />
                  <strong>Type:</strong> Persistent Cookies
                  <br />
                  <strong>Administered by:</strong> Us
                  <br />
                  <strong>Purpose:</strong> These Cookies identify if users have
                  accepted the use of cookies on the Website.
                </li>
                <li>
                  <strong>Functionality Cookies</strong>
                  <br />
                  <strong>Type:</strong> Persistent Cookies
                  <br />
                  <strong>Administered by:</strong> Us
                  <br />
                  <strong>Purpose:</strong> These Cookies allow us to remember
                  choices you make when you use the Website, such as remembering
                  your login details or language preference. The purpose of
                  these Cookies is to provide you with a more personal
                  experience and to avoid you having to re-enter your
                  preferences every time you use the Website.
                </li>
              </ul>

              <p>
                For more information about the cookies we use and your choices
                regarding cookies, please visit our{" "}
                <a href="https://ocean3d.in/cookies-policy">Cookies Policy</a>{" "}
                or the Cookies section of our Privacy Policy.
              </p>

              <h3>Use of Your Personal Data</h3>
              <p>
                The Company may use Personal Data for the following purposes:
              </p>
              <ul>
                <li>
                  To provide and maintain our Service, including to monitor the
                  usage of our Service.
                </li>
                <li>
                  To manage Your Account: to manage your registration as a user
                  of the Service. The Personal Data you provide can give you
                  access to different functionalities of the Service that are
                  available to you as a registered user.
                </li>
                <li>
                  For the performance of a contract: the development, compliance
                  and undertaking of the purchase contract for the products,
                  items or Services you have purchased or of any other contract
                  with us through the Service.
                </li>
                <li>
                  To contact you: To contact you by email, telephone calls, SMS,
                  or other equivalent forms of electronic communication, such as
                  a mobile application's push notifications regarding updates or
                  informative communications related to the functionalities,
                  products or contracted Services, including the security
                  updates, when necessary or reasonable for their
                  implementation.
                </li>
                <li>
                  To provide you with news, special offers and general
                  information about other goods, Services and events which we
                  offer that are similar to those that you have already
                  purchased or enquired about unless You have opted not to
                  receive such information.
                </li>
                <li>
                  To manage your requests: To attend and manage your requests to
                  us.
                </li>
                <li>
                  For business transfers: We may use your information to
                  evaluate or conduct a merger, divestiture, restructuring,
                  reorganisation, dissolution, or other sale or transfer of some
                  or all of our assets, whether as a going concern or as part of
                  bankruptcy, liquidation, or similar proceeding, in which
                  Personal Data held by us about our Service users is among the
                  assets transferred.
                </li>
                <li>
                  For other purposes: We may use your information for other
                  purposes, such as data analysis, identifying usage trends,
                  determining the effectiveness of our promotional campaigns and
                  to evaluate and improve our Service, products, Services,
                  marketing and your experience.
                </li>
              </ul>

              <p>
                We may share Your personal information in the following
                situations:
              </p>
              <ul>
                <li>
                  With Service Providers: We may share your personal information
                  with Service Providers to monitor and analyse the use of our
                  Service, to contact you.
                </li>
                <li>
                  For business transfers: We may share or transfer your personal
                  information in connection with, or during negotiations of, any
                  merger, sale of Company assets, financing, or acquisition of
                  all or a portion of our business to another company.
                </li>
                <li>
                  With Affiliates: We may share your information with our
                  affiliates, in which case we will require those affiliates to
                  honour this Privacy Policy. Affiliates include our parent
                  company and any other subsidiaries, joint venture partners or
                  other companies that we control or that are under common
                  control with us.
                </li>
                <li>
                  With business partners: We may share your information with our
                  business partners to offer you certain products, Services or
                  promotions.
                </li>
                <li>
                  With other users: when you share personal information or
                  otherwise interact in the public areas with other users, such
                  information may be viewed by all users and may be publicly
                  distributed outside.
                </li>
                <li>
                  With Your consent: We may disclose your personal information
                  for any other purpose with Your consent.
                </li>
              </ul>

              <h3>Retention of your Personal Data</h3>
              <p>
                The Company will retain your Personal Data only for as long as
                is necessary for the purposes set out in this Privacy Policy. We
                will retain and use Your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and policies.
              </p>

              <p>
                The Company will also retain Usage Data for internal analysis
                purposes. Usage Data is generally retained for a shorter period
                of time, except when this data is used to strengthen the
                security or to improve the functionality of Our Service, or We
                are legally obligated to retain this data for longer time
                periods.
              </p>

              <h3>Transfer of your Personal Data</h3>
              <p>
                Your information, including Personal Data, is processed at the
                Company's operating offices and in any other places where the
                parties involved in the processing are located. It means that
                this information may be transferred to — and maintained on —
                computers located outside of your state, province, country or
                other governmental jurisdiction where the data protection laws
                may differ from those in your jurisdiction.
              </p>

              <p>
                Your consent to this Privacy Policy followed by your submission
                of such information represents your agreement to that transfer.
              </p>

              <p>
                The Company will take all steps reasonably necessary to ensure
                that your data is treated securely and in accordance with this
                Privacy Policy and no transfer of your Personal Data will take
                place to an organisation or a country unless there are adequate
                controls in place including the security of your data and other
                personal information.
              </p>

              <h3>Delete Your Personal Data</h3>
              <p>
                You have the right to delete or request that we assist in
                deleting the Personal Data that we have collected about you.
              </p>

              <p>
                Our Service may give you the ability to delete certain
                information about you from within the Service.
              </p>

              <p>
                You may update, amend, or delete your information at any time by
                signing in to your Account, if you have one, and visiting the
                account settings section that allows you to manage your personal
                information. You may also contact us to request access to,
                correct, or delete any personal information that you have
                provided to us.
              </p>

              <p>
                Please note, however, that we may need to retain certain
                information when we have a legal obligation or lawful basis to
                do so.
              </p>

              <h3>Disclosure of your Personal Data</h3>

              <h4>Business Transactions</h4>
              <p>
                If the Company is involved in a merger, acquisition or asset
                sale, Your Personal Data may be transferred. We will provide
                notice before your Personal Data is transferred and becomes
                subject to a different Privacy Policy.
              </p>

              <h4>Law enforcement</h4>
              <p>
                Under certain circumstances, the Company may be required to
                disclose your Personal Data if required to do so by law or in
                response to valid requests by public authorities (e.g. a court
                or a government agency).
              </p>

              <h4>Other legal requirements</h4>
              <p>
                The Company may disclose your Personal Data in the good faith
                belief that such action is necessary to:
              </p>
              <ul>
                <li>Comply with a legal obligation</li>
                <li>
                  Protect and defend the rights or property of the Company
                </li>
                <li>
                  Prevent or investigate possible wrongdoing in connection with
                  the Service
                </li>
                <li>
                  Protect the personal safety of Users of the Service or the
                  public
                </li>
                <li>Protect against legal liability</li>
              </ul>

              <h3>Security of Your Personal Data</h3>
              <p>
                The security of your Personal Data is important to us, but
                remember that no method of transmission over the Internet, or
                method of electronic storage is 100% secure. While We strive to
                use commercially acceptable means to protect your Personal Data,
                We cannot guarantee its absolute security.
              </p>

              <h3>Children's Privacy</h3>
              <p>
                Our Service does not address anyone under the age of 13. We do
                not knowingly collect personally identifiable information from
                anyone under the age of 13. If you are a parent or guardian and
                you are aware that your child has provided us with Personal
                Data, please contact us. If we become aware that we have
                collected Personal Data from anyone under the age of 13 without
                verification of parental consent, We take steps to remove that
                information from our servers.
              </p>

              <p>
                If we need to rely on consent as a legal basis for processing
                your information and your country requires consent from a
                parent, We may require your parent's consent before we collect
                and use that information.
              </p>

              <h3>Links to Other Websites</h3>
              <p>
                Our Service may contain links to other websites that are not
                operated by us. If you click on a third party link, You will be
                directed to that third party's site. We strongly advise you to
                review the Privacy Policy of every site you visit.
              </p>

              <p>
                We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third party sites
                or Services.
              </p>

              <h3>Changes to this Privacy Policy</h3>
              <p>
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page. We will let you know via email and/or a prominent
                notice on our Service, prior to the change becoming effective
                and update the "Last updated" date at the top of this Privacy
                Policy. You are advised to review this Privacy Policy
                periodically for any changes. Changes to this Privacy Policy are
                effective when they are posted on this page.
              </p>

              <h3>Contact Us</h3>
              <p>
                If you have any questions about this Privacy Policy, You can
                contact us:
              </p>
              <ul>
                <li>
                  By email:{" "}
                  <a href="mailto:policies@kroila3d.in">policies@kroila3d.in</a>
                </li>
                <li>
                  By visiting this page on our website:{" "}
                  <a href="https://ocean3d.in">https://ocean3d.in</a>
                </li>
                <li>By phone number: 7013099879</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Privacy;
