import React, {useState } from 'react';

function HeaderSearch () {
    const searchParams = new URLSearchParams(window.location.search);
    const search = searchParams.get('s');
    const [ searchTerm, setSearchTerm ] = useState( search );

    function onSearchChange ( e ) {
        setSearchTerm( e.target.value );
    }
    function showSearchForm ( e ) {
        document
        .querySelector( '.header .header-search' )
        .classList.add( 'show' );
    }
    function onSubmitSearchForm ( e ) {
        e.preventDefault();
        window.location = `${process.env.REACT_APP_SHOP_URL}?s=${searchTerm}`;
    }

    return (
        <div className="header-search header-search-extended header-search-visible header-search-no-radius d-none d-lg-block">
            <button className="search-toggle"><i className="icon-search"></i></button>
            <form action="#" method="get" onSubmit={ onSubmitSearchForm } onClick={ showSearchForm }>
                <div className="header-search-wrapper search-wrapper-wide">
                    <label htmlFor="q" className="sr-only" value={ searchTerm }></label>
                    <input type="text" onChange={ onSearchChange } value={ searchTerm } className="form-control" name="q" placeholder="Search product ..." />
                    <button className="btn btn-primary" type="submit"><span className="sr-only">Search</span><i className="icon-search"></i></button>
                </div>
            </form>
        </div >
    );
}

export default  HeaderSearch;