import React from "react";
import PolicyHeader from "../header/Policy-header";
import "./policies.css";
import PageHeader from "../header/page-header";
import Footer from "../Footer";

function TermsConditions() {
  return (
    <div className="policies">
        <PolicyHeader />
        <PageHeader title={"Terms and Conditions"} subTitle="" />
        <div className="container">
            <div className="row">
                <div className="col-md-12 section">
                    <div className="right_text">
                        <span>Last updated: 12th July 2024</span>
                    </div>
                    <div className="content">

                        <p>
                            Welcome to OCEAN 3D! These terms and conditions outline the rules and
                            regulations for the use of KROILA 3D Private Limited's Website, located
                            at <a href="https://ocean3d.in">https://ocean3d.in</a>. By accessing
                            this website we assume you accept these terms and conditions. Do not
                            continue to use OCEAN 3D if you do not agree to take all of the terms
                            and conditions stated on this page.
                        </p>

                        <p>
                            The following terminology applies to these Terms and Conditions, Privacy
                            Statement and Disclaimer Notice and all Agreements: "client", "you" and
                            "your" refers to you, the person log on this website and compliant to
                            the Company's terms and conditions. "The Company", "ourselves", "we",
                            "our" and "us", refers to our Company. "Party", "Parties", or "us",
                            refers to both the client and ourselves. All terms refer to the offer,
                            acceptance and consideration of payment necessary to undertake the
                            process of our assistance to the client in the most appropriate manner
                            for the express purpose of meeting the client's needs in respect of
                            provision of the Company's stated services, in accordance with and
                            subject to, prevailing law of. Any use of the above terminology or other
                            words in the singular, plural, capitalization and/or he/she or they, are
                            taken as interchangeable and therefore as referring to the same.
                        </p>

                        <h2>Cookies</h2>
                        <p>
                            We employ the use of cookies. By accessing OCEAN 3D, you agreed to use
                            cookies in agreement with the KROILA 3D Private Limited's Privacy
                            Policy. Most interactive websites use cookies to let us retrieve the
                            user's details for each visit. Cookies are used by our website to enable
                            the functionality of certain areas to make it easier for people visiting
                            our website. Some of our affiliate/advertising partners may also use
                            cookies.
                        </p>

                        <h2>License</h2>
                        <p>
                            Unless otherwise stated, KROILA 3D Private Limited and/or its licensors
                            own the intellectual property rights for all material on OCEAN 3D. All
                            intellectual property rights are reserved. You may access this from
                            OCEAN 3D for your own personal use subjected to restrictions set in
                            these terms and conditions.
                        </p>

                        <p>You must not:</p>
                        <ul>
                            <li>Republish material from OCEAN 3D</li>
                            <li>Sell, rent or sub-license material from OCEAN 3D</li>
                            <li>Reproduce, duplicate or copy material from OCEAN 3D</li>
                            <li>Redistribute content from OCEAN 3D</li>
                        </ul>

                        <p>
                            This Agreement shall begin on the date hereof. Parts of this website
                            offer an opportunity for users to post and exchange opinions and
                            information in certain areas of the website. KROILA 3D Private Limited
                            does not filter, edit, publish or review comments prior to their
                            presence on the website. Comments do not reflect the views and opinions
                            of KROILA 3D Private Limited, its agents and/or affiliates. Comments
                            reflect the views and opinions of the person who posts their views and
                            opinions. To the extent permitted by applicable laws, KROILA 3D Private
                            Limited shall not be liable for the comments or for any liability,
                            damages or expenses caused and/or suffered as a result of any use of
                            and/or posting of and/or appearance of the comments on this website.
                            KROILA 3D Private Limited reserves the right to monitor all comments and
                            to remove any comments which can be considered inappropriate, offensive
                            or causes breach of these Terms and Conditions.
                        </p>

                        <p>You warrant and represent that:</p>
                        <ul>
                            <li>
                            You are entitled to post the comments on our website and have all
                            necessary licenses and consents to do so;
                            </li>
                            <li>
                            The comments do not invade any intellectual property right, including
                            without limitation copyright, patent or trademark of any third party;
                            </li>
                            <li>
                            The comments do not contain any defamatory, libelous, offensive,
                            indecent or otherwise unlawful material which is an invasion of
                            privacy;
                            </li>
                            <li>
                            The comments will not be used to solicit or promote business or custom
                            or present commercial activities or unlawful activity.
                            </li>
                        </ul>

                        <p>
                            You hereby grant KROILA 3D Private Limited a non-exclusive license to
                            use, reproduce, edit and authorize others to use, reproduce and edit any
                            of your comments in any and all forms, formats or media.
                        </p>

                        <h2>Hyperlinking to our Content</h2>
                        <p>
                            The following organizations may link to our Website without prior
                            written approval:
                        </p>
                        <ul>
                            <li>Government agencies;</li>
                            <li>Search engines;</li>
                            <li>News organizations;</li>
                            <li>
                            Online directory distributors may link to our Website in the same
                            manner as they hyperlink to the Websites of other listed businesses;
                            and
                            </li>
                            <li>
                            System wide Accredited Businesses except soliciting non-profit
                            organizations, charity shopping malls, and charity fundraising groups
                            which may not hyperlink to our Website.
                            </li>
                        </ul>

                        <p>
                            These organizations may link to our home page, to publications or to
                            other Website information so long as the link:
                        </p>
                        <ol>
                            <li>is not in any way deceptive;</li>
                            <li>
                            does not falsely imply sponsorship, endorsement or approval of the
                            linking party and its products and/or services; and
                            </li>
                            <li>fits within the context of the linking party's site.</li>
                        </ol>

                        <p>
                            We may consider and approve other link requests from the following types
                            of organizations:
                        </p>
                        <ul>
                            <li>commonly-known consumer and/or business information sources;</li>
                            <li>dot.com community sites;</li>
                            <li>associations or other groups representing charities;</li>
                            <li>online directory distributors;</li>
                            <li>internet portals;</li>
                            <li>
                            accounting, law, consulting firms, educational institutions and trade
                            associations.
                            </li>
                        </ul>

                        <p>
                            We will approve link requests from these organizations if we decide
                            that:
                        </p>
                        <ol>
                            <li>
                            the link would not make us look unfavorably to ourselves or to our
                            accredited businesses;
                            </li>
                            <li>the organization does not have any negative records with us;</li>
                            <li>
                            the benefit to us from the visibility of the hyperlink compensates the
                            absence of KROILA 3D Private Limited; and
                            </li>
                            <li>the link is in the context of general resource information.</li>
                        </ol>

                        <p>These organizations may link to our home page so long as the link:</p>
                        <ol>
                            <li>is not in any way deceptive;</li>
                            <li>
                            does not falsely imply sponsorship, endorsement or approval of the
                            linking party and its products or services; and
                            </li>
                            <li>fits within the context of the linking party's site.</li>
                        </ol>

                        <p>
                            If you are one of the organizations listed in paragraph 2 above and are
                            interested in linking to our website, you must inform us by sending an
                            email to KROILA 3D Private Limited. Please include your name, your
                            organization name, contact information as well as the URL of your site,
                            a list of any URLs from which you intend to link to our Website, and a
                            list of the URLs on our site to which you would like to link. Wait 2-3
                            weeks for a response.
                        </p>

                        <p>Approved organizations may hyperlink to our Website as follows:</p>
                        <ul>
                            <li>By use of our corporate name; or</li>
                            <li>By use of the uniform resource locator being linked to; or</li>
                            <li>
                            By use of any other description of our Website being linked to that
                            makes sense within the context and format of content on the linking
                            party's site.
                            </li>
                        </ul>

                        <p>
                            No use of KROILA 3D Private Limited's logo or other artwork will be
                            allowed for linking absent a trademark license agreement.
                        </p>

                        <h2>iFrames</h2>
                        <p>
                            Without prior approval and written permission, you may not create frames
                            around our Web Pages that alter in any way the visual presentation or
                            appearance of our Website.
                        </p>

                        <h2>Content Liability</h2>
                        <p>
                            We shall not be held responsible for any content that appears on your
                            Website. You agree to protect and defend us against all claims that are
                            rising on your Website. No link(s) should appear on any Website that may
                            be interpreted as libelous, obscene or criminal, or which infringes,
                            otherwise violates, or advocates the infringement or other violation of,
                            any third party rights.
                        </p>

                        <h2>Reservation of Rights</h2>
                        <p>
                            We reserve the right to request that you remove all links or any
                            particular link to our Website. You approve to immediately remove all
                            links to our Website upon request. We also reserve the right to amend
                            these terms and conditions and its linking policy at any time. By
                            continuously linking to our Website, you agree to be bound to and follow
                            these linking terms and conditions.
                        </p>

                        <h2>Removal of links from our website</h2>
                        <p>
                            If you find any link on our Website that is offensive for any reason,
                            you are free to contact and inform us any moment. We will consider
                            requests to remove links but we are not obligated to or so or to respond
                            to you directly.
                        </p>

                        <p>
                            We do not ensure that the information on this website is correct, we do
                            not warrant its completeness or accuracy; nor do we promise to ensure
                            that the website remains available or that the material on the website
                            is kept up to date.
                        </p>

                        <h2>Disclaimer</h2>
                        <p>
                            To the maximum extent permitted by applicable law, we exclude all
                            representations, warranties and conditions relating to our website and
                            the use of this website. Nothing in this disclaimer will:
                        </p>
                        <ul>
                            <li>
                            limit or exclude our or your liability for death or personal injury;
                            </li>
                            <li>
                            limit or exclude our or your liability for fraud or fraudulent
                            misrepresentation;
                            </li>
                            <li>
                            limit any of our or your liabilities in any way that is not permitted
                            under applicable law; or
                            </li>
                            <li>
                            exclude any of our or your liabilities that may not be excluded under
                            applicable law.
                            </li>
                        </ul>

                        <p>
                            The limitations and prohibitions of liability set in this Section and
                            elsewhere in this disclaimer: (a) are subject to the preceding
                            paragraph; and (b) govern all liabilities arising under the disclaimer,
                            including liabilities arising in contract, in tort and for breach of
                            statutory duty.
                        </p>

                        <p>
                            As long as the website and the information and services on the website
                            are provided free of charge, we will not be liable for any loss or
                            damage of any nature.
                        </p>

                        <h2>Changes to this Terms and Conditions</h2>
                        <p>
                            We may update Our Terms and Conditions from time to time. We will notify
                            you of any changes by posting the new Terms and Conditions on this page.
                        </p>

                        <p>
                            We will let you know via email and/or a prominent notice on our Service,
                            prior to the change becoming effective and update the "Last updated"
                            date at the top of this Terms and Conditions.
                        </p>

                        <p>
                            You are advised to review these Terms and Conditions periodically for
                            any changes. Changes to these Terms and Conditions are effective when
                            they are posted on this page.
                        </p>

                        <h2>Contact Us</h2>
                        <p>
                            If you have any questions about this Terms and Conditions, You can
                            contact us:
                        </p>
                        <ul>
                            <li>
                            By email:{" "}
                            <a href="mailto:policies@kroila3d.in">policies@kroila3d.in</a>
                            </li>
                            <li>
                            By visiting this page on our website:{" "}
                            <a href="https://ocean3d.in">https://ocean3d.in</a>
                            </li>
                            <li>By phone number: 7013099879</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
  );
}

export default TermsConditions;
