import React, { useCallback, useEffect, useRef, useState } from 'react';
import './style.css';
import './fonts-molla.min.css';
import './chillax.css';
import './bootstrap-icons.min.css';
import HomePage from './Components/HomePage';
import MobileMenu from './Components/header/mobile-menu';
import { instance } from './utils/ApiRequest';
import Loader from './Components/Loader';
import {ReactComponent as PrintIcon} from '../src/print.svg';
import MaintananceRoute from './Components/Maintenance/MaintenanceRoute';
import { Navigate, Route, Routes } from 'react-router-dom';
import MaintenancePage from './Components/Maintenance/MaintenancePage';
import CancellationRefund from './Components/Policies/CancellationRefund';
import Privacy from './Components/Policies/Privacy';
import Payment from './Components/Policies/Payment';
import Shipping from './Components/Policies/Shipping';
import TermsConditions from './Components/Policies/TermsConditions';

function App() {
  const scrollTop = useRef(0);
  const [Data, setData] = useState(null);
  const [quickLinks, setQuickLinks] = useState(null)
  const [categories, setCategories] = useState(null);
  const [trendingPros, setTrendingPros] = useState(null);
  const [isMaintenance, setIsMaintenance] =  useState(null);
  const [sections, setSections] = useState(null);
  const [isValidate, setIsValidate] = useState(false);

  useEffect( () => {
    window.addEventListener( 'scroll', scrollHandler, false );
    scrollTop.current = document.querySelector( '#scroll-top' );
}, [] )

  //validate session cookie
  const validateSessionCookie = useCallback(() => {
    instance.get('/account/users/validate-session')
    .then(res => {
      if(res.data){
        if(res.data.Data.User !== null){
          localStorage.setItem('user_logged_in', true);
        }else{
          localStorage.removeItem('user_logged_in');
        }
        setIsValidate(true);
        FetchQuickLinks();
        getBannerData();
        FetchCategories();
        FetchTrending();
        FetchProductSecs();
      }
    }).catch(err => {
      instance.post('/account/users/create-session')
      .then(res => {
        if(res.data){
          validateSessionCookie();
        }
      }).catch(err => {
        
      })
      localStorage.removeItem('user_logged_in');
    })
  },[])

const IsMaintenance = useCallback(async() => {
  try{
    const res = await instance.get('/web/maintenance');
    if(res && res.data && res.data.Data && res.data.Data.Active){
      setIsMaintenance(res.data.Data);
      setIsValidate(true);
    }
  }catch(err){
    validateSessionCookie();
  }
},[validateSessionCookie])


  useEffect(() => {
    IsMaintenance();
  }, [IsMaintenance])

  // fetch banners
  async function getBannerData(){
      await instance.get('/web/banners/fetch')
      .then(res => {
          setData(res.data.Data);
      })
      .catch(err => {
          
      })
  }
  // fetch categories
  async function FetchCategories(){
    await instance.get('/web/categories/fetch')
    .then(res => {
        setCategories(res.data.Data);
    })
    .catch(err => {
        
    })
  }
  // fetch quick links
  async function FetchQuickLinks(){
    await instance.get('/web/quick-links/fetch')
    .then(res => {
      setQuickLinks(res.data.Data);
    })
    .catch(err => {
        
    })
  }
   // fetch trending links
  async function FetchTrending(){
    await instance.get('/web/trending-items/fetch')
    .then(res => {
      setTrendingPros(res.data.Data);
    })
    .catch(err => {
        
    })
  }
  // fetch product sections
  async function FetchProductSecs(){
    await instance.get('/web/product-sections/fetch')
    .then(res => {
      setSections(res.data.Data);
    })
    .catch(err => {
        
    })
  }
  //scroll top 
function toScrollTop () {
  window.scrollTo( {
      top: 0,
      behavior: 'smooth'
  } );
}
function scrollHandler () {
  if ( window.scrollY >= 400 ) {
      scrollTop.current.classList.add( 'show' );
  } else {
      scrollTop.current.classList.remove( 'show' );
  }
}
//end


function hideMobileMenu () {
  document.querySelector( 'body' ).classList.remove( 'mmenu-active' );
}

function mobBottomLinks(link){
  window.location = link;
}

//end mobile side bar
  return (
    <div className="site">
      { isValidate ? 
        <>
          <Routes>
            <Route path='/maintenance' element={<MaintenancePage isMaintenance={isMaintenance} />} />
            <Route element={<MaintananceRoute isMaintenance={isMaintenance}/>}>
              <Route path='/' element={<HomePage Data={Data} categories={categories} trendingPros={trendingPros} sections={sections} quickLinks={quickLinks} />} />
              <Route path='/cancellation-and-refund-policy' element={<CancellationRefund />} />
              <Route path='/privacy-policy' element={<Privacy />} />
              <Route path='/payment-policy' element={<Payment />} />
              <Route path='/shipping-policy' element={<Shipping />} />
              <Route path='/terms-and-conditions' element={<TermsConditions />} />
            </Route>
            <Route path='*' element={<Navigate to='/' />} />
          </Routes>
          {<MobileMenu quickLinks={quickLinks}/>}
        </>
        :
        <Loader />
      }
      <div id='loader'>
        <Loader />
      </div>
      <div className="mobile-menu-overlay" onClick={ hideMobileMenu }></div>
      <button id="scroll-top" title="Back to top" onClick={ toScrollTop }>
          <i className="icon-arrow-up"></i>
      </button>
      {<div className="footer-tik">
          <ul>
            <li onClick={() => mobBottomLinks(process.env.REACT_APP_SHOP_URL)}>
              <a href={process.env.REACT_APP_SHOP_URL}  onClick={(e) => e.preventDefault()} className="wishlist-link">
                <div className="icon">
                  <i className="bi bi-shop"></i>
                  <span>Shop</span>
                </div>
              </a>
            </li>
            <li onClick={() => mobBottomLinks(process.env.REACT_APP_PRINT_URL)}>
              <a href={process.env.REACT_APP_PRINT_URL}  onClick={(e) => e.preventDefault()} className="wishlist-link">
                <div className="icon">
                    <PrintIcon />
                    <span>Print</span>
                </div>
              </a>
            </li>
            <li onClick={() => mobBottomLinks(process.env.REACT_APP_SUPPORT_URL)}>
              <a href={process.env.REACT_APP_SUPPORT_URL}  onClick={(e) => e.preventDefault()} className="wishlist-link">
                <div className="icon">
                  <i className="bi bi-patch-question"></i>
                  <span>Help</span>
                </div>
              </a>
            </li>
          </ul>
        </div>}
    </div>
  );
}

export default App;
