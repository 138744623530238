import React from "react";
import PolicyHeader from "../header/Policy-header";
import "./policies.css";
import PageHeader from "../header/page-header";
import Footer from "../Footer";

function CancellationRefund() {
    return (
        <div className="policies">
            <PolicyHeader />
            <PageHeader title={"Cancellation/Refund Policy"} subTitle="" />
            <div className="container">
                <div className="row">
                    <div className="col-md-12 section">
                        <div className="right_text">
                            <span>Last updated: 16th August 2024</span>
                        </div>
                        <div className="content">
                            <p>
                                Thank you for shopping at OCEAN 3D. We strive to
                                ensure that you are completely satisfied with
                                your purchase. If, for any reason, your purchase
                                does not meet your expectations, we invite you
                                to review our policy on returns and
                                cancellations.
                            </p>

                            <h2>Definitions</h2>
                            <p>
                                For the purposes of this Return and Refund
                                Policy:
                            </p>
                            <ul>
                                <li>
                                    <strong>Company</strong> (referred to as
                                    either "the Company", "we", "us" or "our" in
                                    this agreement) refers to KROILA 3D Private
                                    Limited, 304, 4th floor, Yerramsetty Towers,
                                    Narasaraopet - 522601.
                                </li>
                                <li>
                                    <strong>Goods</strong> refer to the items
                                    offered for sale on the service.
                                </li>
                                <li>
                                    <strong>Orders</strong> mean a request by
                                    you to purchase goods from us.
                                </li>
                                <li>
                                    <strong>Service</strong> refers to the
                                    Website.
                                </li>
                                <li>
                                    <strong>Website</strong> refers to OCEAN 3D,
                                    accessible from{" "}
                                    <a href="https://ocean3d.in">
                                        https://ocean3d.in
                                    </a>
                                    .
                                </li>
                                <li>
                                    <strong>You</strong> mean the individual
                                    accessing or using the service, or the
                                    company, or other legal entity on behalf of
                                    which such individual is accessing or using
                                    the service, as applicable.
                                </li>
                            </ul>

                            <h2>Your Order Cancellation Rights</h2>

                            <h3>Orders from Ocean 3D Shop</h3>
                            <p>
                                Orders placed on Ocean 3D Shop can be cancelled
                                at any time before delivery.
                            </p>

                            <h3>Orders from Ocean 3D Print</h3>
                            <p>
                                Orders placed on Ocean 3D Print, also known as
                                custom model orders, can be cancelled before the
                                processing has begun. The cancellation of a
                                print order is at the discretion of KROILA 3D
                                Private Limited and is subject to the status of
                                the processing.
                            </p>

                            <p>
                                In order to exercise your right of cancellation,
                                you must inform us of your decision by means of
                                a clear statement. You can inform us of your
                                decision by:
                            </p>
                            <ul>
                                <li>
                                    By email:{" "}
                                    <a href="mailto:support@ocean3d.in">
                                        support@ocean3d.in
                                    </a>
                                </li>
                                <li>
                                    By visiting this page on our website:{" "}
                                    <a href="https://support.ocean3d.in">
                                        https://support.ocean3d.in
                                    </a>
                                </li>
                                <li>By phone number: +91 70130 99879</li>
                            </ul>

                            <p>
                                We will reimburse you no later than 14 days from
                                the day on which we receive the returned goods.
                                We will use the same means of payment as you
                                used for the order, and you will not incur any
                                fees for such reimbursement.
                            </p>

                            <h2>Return Policy</h2>

                            <h3>Returns for Ocean 3D Shop</h3>
                            <ul>
                                <li>
                                    We offer{" "}
                                    <strong>free returns within 7 days</strong>{" "}
                                    of delivery for products purchased from
                                    Ocean 3D Shop.
                                </li>
                                <li>
                                    To be eligible for a return, the product
                                    must be unused and in the same condition
                                    that you received it. It should also be in
                                    the original packaging.
                                </li>
                                <li>
                                    If we are unable to schedule a pickup for
                                    the return, you are responsible for the cost
                                    and risk of returning the goods to us.
                                </li>
                            </ul>

                            <h3>Returns for Ocean 3D Print</h3>
                            <ul>
                                <li>
                                    Orders from Ocean 3D Print are{" "}
                                    <strong>
                                        not returnable unless they are damaged
                                    </strong>
                                    . If you receive a damaged product, please
                                    notify us within 48 hours and provide
                                    relevant proofs.
                                </li>
                            </ul>

                            <h2>Conditions for Returns</h2>
                            <ul>
                                <li>
                                    For Ocean 3D Shop: Returns are accepted
                                    within 7 days from the date of delivery. The
                                    product must be unused, in its original
                                    packaging, and in the same condition as
                                    received.
                                </li>
                                <li>
                                    For Ocean 3D Print: Returns are only
                                    applicable if the product is damaged.
                                    Notification of the damage and submission of
                                    relevant proofs must be made within 48 hours
                                    from the date of delivery.
                                </li>
                                <li>
                                    We reserve the right to refuse returns of
                                    any item that does not meet the above
                                    conditions at our sole discretion.
                                </li>
                            </ul>

                            <h2>Returning Goods</h2>
                            <p>
                                If we are unable to schedule a pickup for the
                                return, you should send the goods to the
                                following address:
                            </p>

                            <p>
                                KROILA 3D Private Limited
                                <br />
                                304, Yerramsetty Towers,
                                <br />
                                Narasaraopet - 522601
                                <br />
                                Andhra Pradesh.
                            </p>

                            <p>
                                We cannot be held responsible for goods damaged
                                or lost in return shipment. Therefore, we
                                recommend using an insured and trackable mail
                                service. We are unable to issue a refund without
                                actual receipt of the goods or proof of received
                                return delivery.
                            </p>

                            <h2>Contact Us</h2>
                            <p>
                                If you have any questions about our returns and
                                refunds policy, please contact us:
                            </p>
                            <ul>
                                <li>
                                    By email:{" "}
                                    <a href="mailto:support@ocean3d.in">
                                        support@ocean3d.in
                                    </a>
                                </li>
                                <li>
                                    By visiting this page on our website:{" "}
                                    <a href="https://support.ocean3d.in">
                                        https://support.ocean3d.in
                                    </a>
                                </li>
                                <li>By phone number: +91 70130 99879</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default CancellationRefund;
