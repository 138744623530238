import React, { useState } from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';

function MobileMenu({quickLinks}) {
    const [ searchTerm, setSearchTerm ] = useState( "" );

    function hideMobileMenu() {
        document.querySelector( 'body' ).classList.remove( 'mmenu-active' );
    }
    function onSearchChange( e ) {
        setSearchTerm( e.target.value );
    }
    function onSubmitSearchForm( e ) {
        e.preventDefault();
    }
    return (
        <div className="mobile-menu-container">
            <div className="mobile-menu-wrapper">
                <span className="mobile-menu-close" onClick={ hideMobileMenu }><i className="icon-close"></i></span>

                <form action="#" method="get" onSubmit={ onSubmitSearchForm } className="mobile-search">
                    <label htmlFor="mobile-search" className="sr-only">Search</label>
                    <input type="text" className="form-control" value={ searchTerm } onChange={ onSearchChange } name="mobile-search" id="mobile-search" placeholder="Search product ..." required />
                    <button className="btn btn-primary" type="submit"><i className="icon-search"></i></button>
                </form>

                <Tabs defaultIndex={ 0 } selectedTabClassName="show">
                    <TabList className="nav nav-pills-mobile" role="tablist">
                        <Tab className="nav-item text-center">
                            <span className="nav-link">Menu</span>
                        </Tab>

                        <Tab className="nav-item text-center">
                            <span className="nav-link">Quick links</span>
                        </Tab>
                    </TabList>

                    <div className="tab-content">
                        <TabPanel>
                            <nav className="mobile-nav">
                                <ul className="mobile-menu">
                                    <li>
                                        <a href="/" className="sf-with-ul">Home</a>
                                    </li>
                                    <li>
                                        <a href={`${process.env.REACT_APP_SHOP_URL}`} className="sf-with-ul" scroll={ false }>Shop</a>
                                    </li>
                                    <li>
                                        <a href={`${process.env.REACT_APP_PRINT_URL}`} className="sf-with-ul">Print</a>
                                    </li>
                                    <li>
                                        <a href={`${process.env.REACT_APP_SUPPORT_URL}`} className="sf-with-ul">Support</a>
                                    </li>
                                </ul>
                            </nav>
                        </TabPanel>
                        <TabPanel>
                            <nav className="mobile-cats-nav">
                                <ul className="mobile-cats-menu">
                                    {quickLinks && quickLinks.map((link, index) => (
                                        <li key={index}>
                                            <a href={link.URL}>{link.Title}</a>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </TabPanel>
                    </div>
                </Tabs>

                <div className="social-icons">
                    <a href="https://www.facebook.com/ocean3d.in" target='_blank' rel="noreferrer" className="social-icon" title="Facebook"><i className="icon-facebook-f"></i></a>
                    <a href="https://www.instagram.com/ocean3d.in/" target='_blank' rel="noreferrer" className="social-icon" title="Instagram"><i className="icon-instagram"></i></a>
                    <a href="https://www.youtube.com/@ocean3dindia" target='_blank' rel="noreferrer" className="social-icon" title="Youtube"><i className="icon-youtube"></i></a>
                </div>
            </div>
        </div>
    )
}

export default MobileMenu;