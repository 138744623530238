import { useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Reveal from 'react-awesome-reveal';
import StickyBox from 'react-sticky-box';
import { introSlider, brandSlider, fadeInUpShorter, fadeInUp, fadeInRightShorter } from '../utils/data';
import OwlCarouselSlider from './owl-carousel';
import TrendyCollection from './home/trendy-collection';
import ProductsCollection from './home/product-collection';
import SEO from './SEO/SEO';
import Header from './header/Header';
import Footer from './Footer';
import { toKebabCase } from '../utils/ApiRequest';


function Home(props) {
    const {loading=false, Data, categories, trendingPros, sections, quickLinks } = props;

    const toggleSidebar = () => {
        if (
            document
                .querySelector( 'body' )
                .classList.contains( 'sidebar-home-active' )
        ) {
            document
                .querySelector( 'body' )
                .classList.remove( 'sidebar-home-active' );
        } else {
            document
                .querySelector( 'body' )
                .classList.add( 'sidebar-home-active' );
        }
    }

    const hideSidebar = () => {
        document
            .querySelector( 'body' )
            .classList.remove( 'sidebar-home-active' );
    }

    const resizeHandler = () => {
        if ( window.innerWidth > 1199 ) document.querySelector( '.sidebar-fixed-toggler' ).classList.add( 'd-none' );
        else document.querySelector( '.sidebar-fixed-toggler' ).classList.remove( 'd-none' );
    }
    useEffect( () => {
        resizeHandler();
        window.addEventListener( 'resize', resizeHandler, {
            passive: true
        } );

        return () => {
            window.removeEventListener( 'resize', resizeHandler );
        }
    }, [] )

    return (
        <>
        <Header quickLinks={quickLinks}/>
        <div className="main home-page skeleton-body">
            <SEO 
                title='Ocean 3D: Best Customized & Readymade 3D Printing Services in India
            | Ocean3D.in'
                description='Discover Ocean 3D for top customized and readymade 3D printing services in India. Quality custom and ready 3D printed products, planters, vases, and more. Visit Ocean3D.in now!'
                name='Ocean 3d'
                type='article'
            />
            <div className="mb-lg-2"></div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-9 col-xxl-8 offset-lg-3 offset-xxl-2">
                        <div className="intro-slider-container slider-container-ratio mb-4">
                           {Data &&
                            <OwlCarouselSlider adClass="owl-simple intro-slider owl-nav-inside" options={ introSlider }>
                                {Data && Data.CarouselItems.map((item, index) => (
                                    <div className="intro-slide" key={index}>
                                        <figure className="slide-image mb-0">
                                            <img
                                                alt={item.AltText}
                                                src={item.ImageUrl}
                                                width="1180"
                                                height="500"
                                            />
                                        </figure>
                                        <div className="intro-content">
                                            <Reveal keyframes={ fadeInUpShorter } delay={ 100 } duration={ 1000 }>
                                                <h3 className={`intro-subtitle text-white`}>{item.Caption}</h3>
                                                <h1 className={`intro-title text-white`}>
                                                    {item.Title}
                                                </h1>
                                                <div className={`intro-text text-white`}>{item.SubTitle}</div>
                                                {item.ButtonText &&
                                                    <a href= {item.ButtonUrl} className="btn btn-primary">
                                                    <span>{item.ButtonText}</span>
                                                </a>}
                                            </Reveal>
                                        </div>
                                    </div>
                                ))}
                                </OwlCarouselSlider>
                            }
                        </div>
                    </div>
                    <div className="col-xl-3 col-xxl-2 d-none d-xxl-block">
                        <div className="banner intro-banner banner-overlay banner-content-stretch">
                            <a href={Data && Data.RightPanel.ButtonUrl}>
                                <div className="lazy-overlay bg-transparent"></div>
                                <LazyLoadImage
                                    alt={Data && Data.RightPanel.AltText}
                                    src={Data && Data.RightPanel.ImageUrl}
                                    threshold={ 200 }
                                    width="250"
                                    height="730"
                                    effect="blur"
                                />
                            </a>
                            <div className="banner-content text-right">
                                <div className="price text-center">
                                    <h3 className={`intro-subtitle text-white`}>{Data && Data.RightPanel.SubTitle}</h3>
                                    <h1 className="text-white">{Data && Data.RightPanel.Title}</h1>
                                    <div className={`intro-text text-white`}>{Data && Data.RightPanel.Caption}</div>
                                </div>
                                {Data && Data.RightPanel.ButtonText &&
                                    <a href={Data && Data.RightPanel.ButtonUrl} className="btn btn-primary">
                                    {Data && Data.RightPanel.ButtonText}
                                </a>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-9 col-xxl-10">
                        <Reveal keyframes={ fadeInUp } delay={ 500 } duration="500" triggerOnce>
                            {
                                categories &&
                                <OwlCarouselSlider adClass="brands-slider brands-carousel brands-border" options={ brandSlider }>
                                    {
                                        categories && categories.map( ( category, index ) => {
                                            return (
                                                <a href={`${process.env.REACT_APP_SHOP_URL}categories/${toKebabCase(category.Name)}`} className="brand" key={ index } >
                                                    <img src={ category.ImageSmall } alt="brand" width='100px' height='30px' />
                                                    <span>{category.Name}</span>
                                                </a>
                                            )
                                        } )
                                    }
                                </OwlCarouselSlider>
                            }
                        </Reveal>
                        <div className='mb-4'></div>
                        <div className="row">
                            {
                                Data && Data.Banners.map((item, index) => (
                                    <div className="col-md-6" key={index}>
                                        <Reveal keyframes={ fadeInUp } delay={ 200 } duration={ 1000 } triggerOnce>
                                            <div className="banner banner-overlay">
                                                <a href={item.ButtonUrl}>
                                                    <div className="lazy-overlay"></div>
                                                    <LazyLoadImage
                                                        alt="Banner"
                                                        src={item.ImageUrl}
                                                        threshold={ 200 }
                                                        width="580"
                                                        effect="blur"
                                                    />
                                                </a>
                                                <div className="banner-content">
                                                    <h4 className={`banner-subtitle text-white d-none d-sm-block`}>
                                                        {item.SubTitle}
                                                    </h4>
                                                    <h3 className={`banner-title text-white`}>
                                                        {item.Title}
                                                    </h3>
                                                    {item.ButtonText &&
                                                        <a href={item.ButtonUrl} className="btn btn-primary">
                                                        {item.ButtonText}
                                                    </a>}
                                                </div>
                                            </div>
                                        </Reveal>
                                    </div>
                                ))
                            }

                        </div>

                        <div className="mb-4"></div>

                        <Reveal keyframes={ fadeInRightShorter } delay={ 300 } duration="1000" triggerOnce>
                            <TrendyCollection products={ trendingPros || [] } loading={ loading } />
                        </Reveal>

                        <div className="mb-5"></div>

                        {sections &&
                            sections.map((section, index) => (
                            <Reveal keyframes={ fadeInRightShorter } delay={ 300 } duration="1000" key={index} triggerOnce>
                                <ProductsCollection products={ section.Items } loading={ loading } banner={section.Banner} />
                            </Reveal>
                            ))
                        }

                        <div className="icon-boxes-container">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-6 col-lg-3">
                                        <div className="icon-box icon-box-side">
                                            <span className="icon-box-icon text-dark">
                                                <i className="icon-rocket"></i>
                                            </span>
                                            <div className="icon-box-content">
                                                <h3 className="icon-box-title">Free Shipping</h3>

                                                <p>Orders ₹1000 or more</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-lg-3">
                                        <div className="icon-box icon-box-side">
                                            <span className="icon-box-icon text-dark">
                                                <i className="icon-tag"></i>
                                            </span>

                                            <div className="icon-box-content">
                                                <h3 className="icon-box-title">Additional 10% Off</h3>

                                                <p>Orders above ₹1500</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-lg-3">
                                        <div className="icon-box icon-box-side">
                                            <span className="icon-box-icon text-dark">
                                                <i className="icon-tag"></i>
                                            </span>

                                            <div className="icon-box-content">
                                                <h3 className="icon-box-title">Additional 15% Off</h3>

                                                <p>Orders above ₹2500</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-lg-3">
                                        <div className="icon-box icon-box-side">
                                            <span className="icon-box-icon text-dark">
                                                <i className="icon-tag"></i>
                                            </span>

                                            <div className="icon-box-content">
                                                <h3 className="icon-box-title">Additional 20% Off</h3>

                                                <p>Orders above ₹3500</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-5"></div>
                    </div>

                    <aside className="col-xl-3 col-xxl-2 order-xl-first">
                        <button className="sidebar-fixed-toggler" onClick={ toggleSidebar }><i className="icon-cog"></i></button>
                        <div className="sidebar-filter-overlay" onClick={ hideSidebar }></div>
                        <StickyBox className="sticky-content" offsetTop={ 70 }>
                            <div className="sidebar sidebar-home">
                                <div className="row">
                                    {Data && Data.LeftPanels.map((ele, index) => 
                                    (
                                        <div className="col-xl-12" key={index}>
                                            <div className="widget widget-banner">
                                                <div className="banner banner-overlay">
                                                    <a href={ele.ButtonUrl}>
                                                        <div className="lazy-overlay"></div>
                                                        <LazyLoadImage
                                                            alt={ele.AltText}
                                                            src={ele.ImageUrl}
                                                            threshold={ 200 }
                                                            width="280"
                                                            height={ 400 }
                                                            effect="blur"
                                                        />
                                                    </a>

                                                    <div className="banner-content banner-content-top banner-content-right text-right">
                                                        <h3 className="banner-title text-white">
                                                            <a href={ele.ButtonUrl}>
                                                                {ele.Title}<br />
                                                                <span className='text-white'>{ele.SubTitle}</span>
                                                            </a>
                                                        </h3>

                                                        {ele.ButtonText &&
                                                            <a href={ele.ButtonUrl} className="btn btn-primary">
                                                            {ele.ButtonText}
                                                        </a>}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    ))}

                                    <div className="col-xl-12">
                                        {/* <BlogCollection posts={ posts } loading={ loading } /> */}
                                    </div>
                                </div>
                            </div>
                        </StickyBox>
                    </aside>
                </div>
            </div>
            {/* <NewsletterModal /> */}
        </div>
        <Footer />
        </>
    )
}

export default Home;