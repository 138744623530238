import React from 'react';
import { Link } from 'react-router-dom';

function PageHeader ( props ) {
    const { title, subTitle } = props;

    return (
        <div className="page-header text-center" style={ { background: '#000' } } >
            <div className="container">
                <h1 className="page-title">{ title }<span>{ subTitle }</span></h1>
                <ul>
                    <li>
                        <Link to='/' >Home</Link>
                    </li>
                    <li>/</li>
                    <li>
                        {title}
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default PageHeader;