import React, { useEffect, useRef, useState } from 'react'
import useSize from '../Size/useSize'
import { instance } from '../../utils/ApiRequest';

function TopHeader() {
    const [isToggle, setIsToggle] = useState(false);
    const dropdownRef = useRef(null);
    const width = useSize();
    const user = localStorage.getItem('user_logged_in');
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsToggle(false);
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    const HandleLogout = (e) => {
        e.preventDefault();
        instance.post('/account/users/logout')
        .then(res => {
            if(res.data){
                window.location.reload();
            }
        }).catch(err => {
        
        })
    }

  return (
    <nav className="py-2 container-fluid">
        <div className="d-flex flex-wrap top-header">
            {
            width < 430 ? 
                <ul className="nav me-auto">
                    <li className="nav-item"><a href="https://api.whatsapp.com/send?phone=917013099879" rel="noreferrer" target="_blank" className="nav-link whatsapp link-body-emphasis px-2"><i className="bi bi-whatsapp"></i>  &nbsp;+91 70130 99879</a></li>
                    {user && <li className="nav-item nav-link link-body-emphasis px-2">|</li>}
                    {user && <li className="nav-item"><a href="https://www.facebook.com/ocean3d.in" rel="noreferrer" target="_blank" className="nav-link facebook link-body-emphasis px-2"><i className="bi bi-facebook"></i></a></li>}
                    {user && <li className="nav-item"><a href="https://www.instagram.com/ocean3d.in" rel="noreferrer" target="_blank" className="nav-link instagram link-body-emphasis px-2"><i className="bi bi-instagram"></i></a></li>}
                    {user && <li className="nav-item"><a href="https://www.youtube.com/@ocean3dindia" rel="noreferrer" target="_blank" className="nav-link youtube link-body-emphasis px-2"><i className="bi bi-youtube"></i></a></li>}
                </ul>
            :
                <ul className="nav me-auto">
                    <li className="nav-item"><a href="https://api.whatsapp.com/send?phone=917013099879" rel="noreferrer" target="_blank" className="nav-link whatsapp link-body-emphasis px-2"><i className="bi bi-whatsapp"></i>  &nbsp;+91 70130 99879</a></li>
                    <li className="nav-item nav-link link-body-emphasis px-2">|</li>
                    <li className="nav-item"><a href="https://www.facebook.com/ocean3d.in" rel="noreferrer" target="_blank" className="nav-link facebook link-body-emphasis px-2"><i className="bi bi-facebook"></i></a></li>
                    <li className="nav-item"><a href="https://www.instagram.com/ocean3d.in" rel="noreferrer" target="_blank" className="nav-link instagram link-body-emphasis px-2"><i className="bi bi-instagram"></i></a></li>
                    <li className="nav-item"><a href="https://www.youtube.com/@ocean3dindia" rel="noreferrer" target="_blank" className="nav-link youtube link-body-emphasis px-2"><i className="bi bi-youtube"></i></a></li>
                </ul>
            }
            {
                !user ? 
                (
                    <ul className="nav">
                        <li className="nav-item"><a href={`${process.env.REACT_APP_ACCOUNT_URL}login?redirectTo=${window.location.href}`} className="nav-link link-body-emphasis px-2">Login / Signup</a></li>
                    </ul>
                )
                :
                (
                    width > 430 ?
                        <ul className="nav logged">
                            <li className="nav-item"><a href={`${process.env.REACT_APP_ACCOUNT_URL}account`} className="nav-link link-body-emphasis px-2">My Account</a></li>
                            <li className="nav-item"><a href={`${process.env.REACT_APP_ACCOUNT_URL}orders`} className="nav-link link-body-emphasis px-2">Orders</a></li>
                            <li className="nav-item"><span className="nav-link link-body-emphasis px-2 text-danger" onClick={HandleLogout}>Logout</span></li>
                        </ul>
                    :
                    <div className="flex-shrink-0 dropdown mobile-top-menu" ref={dropdownRef} onClick={() => setIsToggle(!isToggle)}>
                        <span className="d-flex link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="https://cdn.ocean3d.in/global/profile.png" alt="mdo" width="32" height="32" className="rounded-circle" />
                        </span>
                        <ul className={`dropdown-menu text-small shadow ${isToggle ? "show" : ""}`}>
                            <li><a className="dropdown-item" href={`${process.env.REACT_APP_ACCOUNT_URL}profile`} >Profile</a></li>
                            <li><a className="dropdown-item" href={`${process.env.REACT_APP_ACCOUNT_URL}orders`} >Order History</a></li>
                            <li><a className="dropdown-item" href={`${process.env.REACT_APP_ACCOUNT_URL}coupons`} >Offers</a></li>
                            <li><a className="dropdown-item" href={`${process.env.REACT_APP_ACCOUNT_URL}addresses`} >Addresses</a></li>
                            <li><a className="dropdown-item" href={`${process.env.REACT_APP_ACCOUNT_URL}notification-preferences`} >Notification Preferences</a></li>
                            <li><hr className="dropdown-divider" /></li>
                            <li><span className="dropdown-item text-danger" onClick={HandleLogout}>Sign out</span></li>
                        </ul>
                    </div>
                )
            }
        </div>
    </nav>
  )
}

export default TopHeader